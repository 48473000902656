;(function () {
	window.clickOff = function ($el, callback) {
		if (!$el) return false;
		$(document).on('click', function (e) {
			if ($el.has(e.target).length === 0 && !$el.is(e.target)) {
				callback($el);
			}
		});
	}
}());

$(function () {
	/* open menu */
	$('[data-toggle="menu"]').on('click', function () {
		$('#burger-menu').toggleClass('b-burgerMenu_opened');
	});
});

$(function () {
	/* open search */
	$('[data-toggle="search"]').on('click', function () {
		$('#searchLine').toggleClass('b-search_opened');
		$('body').toggleClass('_overflow');
	});

	$('.overlay').on('click', function () {
		if ($('#searchLine').hasClass('b-search_opened')) {
			$('#searchLine').removeClass('b-search_opened');
			$('body').removeClass('_overflow');
		}
	});
});

$(function () {
	/* open basket in header */
	$('[data-toggle="basketHeader"]').on('click', function () {
		$('#basket_popup').toggleClass('b-basket__popup_opened');
	});

	/* hide basket in header */
	window.clickOff($('#basket_popup'), function () {
		if ($('#basket_popup').hasClass('b-basket__popup_opened')) {
			if (!$(event.target).closest('[data-toggle="basketHeader"]').length) {
				event.preventDefault();
				$('#basket_popup').removeClass('b-basket__popup_opened');
			}
		}
	});
});

// Лэзилоад классов для анимации
$(function () {

	let self = {
		observer: null
	};

	let observerConfig = {};

	self.observer = new IntersectionObserver(function (entries) {
		Array.prototype.forEach.call(entries, function (entry) {
			if (entry.isIntersecting) {
				self.observer.unobserve(entry.target);

				let block = $(entry.target);
				let className = block.data('animation');

				setTimeout(() => {
					block.addClass(className);
				}, 400);
			}
		});
	}, observerConfig);

	$("[data-animation]").each((index, tag) => {
		self.observer.observe(tag);
	});
});

// Открытие формы Отзывов
$(function () {
	$('[data-toggle="collapse"]').on('click', function () {
		var id = $(this).data("toggle-id");
		if (id) {
			$('#' + id).slideToggle('slow', 'linear');
		}
	});
});

// Открытие меню Каталога разделов для мобильных по клику
$(function () {
	if ($(window).width() <= 360) {
		$('#catalogTitle').on('click', function () {
			$('#menuSection').toggleClass('b-menuSidebar_opened');
		});
	}
});

$(function () {
	$('#categoryFilter').on('click', function () {
		$('.b-filter').toggleClass('b-filter_opened');
	});
});


// Открыть/скрыть детально описание вакансии
function openDetailVacancies(element) {
	var btn = $(element),
		vacancyList = btn.parents(".b-opener");

	vacancyList.toggleClass('b-opener_opened');

	btn.toggleClass('icon--plus');
	btn.toggleClass('icon--minus');
}

// Плавное перемещение к якорю
$(function () {
	$('a[href^="#"]').click(function () {
		let anchor = $(this).attr('href');
		$('html, body').animate({
			scrollTop: $(anchor).offset().top
		}, 400);
	});
});

function bindModalLinks(link) {
	var linkOb = link || $('[data-toggle="modal"]');

	/*magnific-popup*/
	linkOb.magnificPopup({
		type: 'ajax',
		preloader: true,
		closeOnBgClick: false,
		mainClass: 'mfp-fade',
		focus: 'input[type="text"], textarea, select',
		fixedContentPos: true,
		callbacks: {
			beforeOpen: function () {
				$('html').addClass('mfp-helper');
			},
			close: function () {
				$('html').removeClass('mfp-helper');
			}
		}
	});
}

$(function () {
	bindModalLinks();
});


// Открытие заказа в истории заказов
$(function () {
	$('[data-toggle="spoiler"]').on('click', function (e) {
		$(this).parents(".b-orderList__spoiler").toggleClass('b-orderList__spoiler_opened').toggleClass('b-orderList__spoiler_closed');
		$(this).parents(".b-orderList__row").siblings(".b-orderList__spoilerBody").slideToggle(400);
	});
});

$(function () {
	$('.catalog-menu__link').on('click', function (e) {
		if($(e.target).hasClass('catalog-menu__link-name')) {
			return;
		}
		e.preventDefault();
		this.classList.toggle('_open');
	});
	$('.catalog-submenu__header').on('click', function () {
		$(this).closest('.catalog-menu__item').find('.catalog-menu__link').removeClass('_open');
	});
	$('.catalog-menu__open').on('click', function () {
		this.classList.toggle('_open');
	});
	$('.catalog-submenu__header').on('click', function () {
		$(this).closest('.catalog-menu__item').find('.catalog-menu__link').removeClass('_open');
	});
});

;(function () {
	window.onAfterAjaxLoadDataCompleted = function (observerImg) {
		setTimeout(function() {
			let arrayImg = document.querySelectorAll('source[data-srcset], img[data-src]');
			for(let i = 0; i < arrayImg.length; i++) {
				observerImg.observe(arrayImg[i]);
			}
		}, 100);
	}
}());




$(function () {
	window.NO_IMAGE = "<?= Citrus\Tools\Template::getDefaultImg();?>";
	window.ON_UPDATE_DOM = 'contentReady';

	let observerImg = new IntersectionObserver((entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {

				if(entry.target.hasAttribute('data-srcset')) {
					let datasrcWebp = entry.target.getAttribute('data-srcset');
					let srcWebp = document.createAttribute('srcset');
					srcWebp.value = datasrcWebp;
					entry.target.setAttributeNode(srcWebp);
					entry.target.removeAttribute('data-srcset');

					observer.unobserve(entry.target);
				}

				if(entry.target.hasAttribute('data-src') && $(entry.target).is(":visible")) {
					let datasrc = entry.target.getAttribute('data-src');
					let src = document.createAttribute('src');
					src.value = datasrc;
					entry.target.setAttributeNode(src);
					entry.target.removeAttribute('data-src');

					observer.unobserve(entry.target);
				}
			}
		})
	}, {
		root: null, // родитель целевого элемента - область просмотра
		rootMargin: '0px', // без отступов
		threshold: 0.1 // процент пересечения - половина изображения
	});

	let arrayImg = document.querySelectorAll('source[data-srcset], img[data-src]');
	for(let i = 0; i < arrayImg.length; i++) {
		observerImg.observe(arrayImg[i]);
	}

	BX.addCustomEvent("SearchTitleResult:ajaxLoadDataCompleted", () => {
		window.onAfterAjaxLoadDataCompleted(observerImg);
	});

	BX.addCustomEvent("CatalogSectionDefault:ajaxLoadDataCompleted", () => {
		window.onAfterAjaxLoadDataCompleted(observerImg);
	});

	BX.addCustomEvent("Citrus.Component.Element.List:ajaxLoadDataCompleted", () => {
		window.onAfterAjaxLoadDataCompleted(observerImg);
	});

	BX.addCustomEvent(window.ON_UPDATE_DOM, function() {
		setTimeout(function() {
			let arrayImg = document.querySelectorAll('source[data-srcset], img[data-src]');
			for(let i = 0; i < arrayImg.length; i++) {
				observerImg.observe(arrayImg[i]);
			}
		}, 100);
	});
});
